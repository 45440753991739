export const componentDimensions = {
    brandBanner: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    brandGrid: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    brandGridHover: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    broadBanner: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    broadPromo: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    featuredList: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    featuredListCarousel: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    gridBoxFour: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    gridBoxFourBottom: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    gridBoxFive: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    gridBoxFiveBottom: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    gridBoxFiveLong: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    gridBoxFiveLongBottom: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    heroComponent: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    heroComponentfifty: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    login: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    textMediaCardThird: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    textMediaCardTwoThird: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    textMediaCardHalf: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
    textMediaCardFull: {
        xs: {
            name: 'bds-hq',
        },
        md: {
            name: 'bds-hq',
        },
    },
}
