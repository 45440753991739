export const commonVariable = {
    accountMenuTag: 'ul#bdsAccountsMenu',
    fadeTime: 300,
    fadeSixHundred: 600,
    brandMenuDropDown: '#brandMenuDropDown',
    universalProfileDropown: '#profileUniversalDropDown',
    responsiveMenu: '.nl-brand-responsive-menu',
    mobileSecondaryNavigation: '#mobile-secondary-navigation',
    one: 1,
    minusOne: -1,
    minusTwo: -2,
    three: 3,
    twoHundred: 200,
    tertiaryContainer: '.bds-tertiary-container',
    tertiaryBackdrop: 'tertiarynavigation--backdrop',
    hamburgerMenulist: '#hamburger-menulist',
    mobileSecondaryNavigationHeight: 'mobile-secondary-navigation-height',
    svghevronUp: 'svg.nl-icon-ct-chevron-up',
    svghevronDown: 'svg.nl-icon-ct-chevron-down',
    cmpNavigationItemLevel: '.cmp-navigation__item.cmp-navigation__item--level-0',
    bdsMiddleContent: '.bds-middle-content h2',
    eighty: 80,
    ten: 10,
    ninety: 90,
    fifty: 50,
    showMore: '.nl-showmore',
    showLess: '.nl-showless',
    showResultLimit: 17,
    brandDropdownlistLeftMenu: '.nl-brand-dropdownlist__left__menu:gt(15)',
    showMoreRight: '.nl-showmore-right',
    showLessRight: '.nl-showless-right',
    brandDropdownlistRightMenu: '.nl-brand-dropdownlist__right__menu:gt(15)',
    styleBackground: 'background-color',
    cmpTabActive: '.cmp-tabs__tab.cmp-tabs__tab--active',
    attributeDataBackground: 'data-background',
    mobileWidth: 768,
    tertiaryNav: '.tertiarynavigation .bds-tertiary-navigation',
    tabList: 'li.cmp-tabs__tab',
    profileListItem: 'ul#profileList',
    videoModal: '.video-component-modal',
    videoImage: '.video-component-image',
    breadCrumbRight: '.breadcrumb-chevron-right',
    two: 2,
    customoption: '.custom-options .custom-option#',
    selectText: '.select__trigger span',
    searchwrapperdiv: '#wrapperID',
    searchMobileIconContainer: '.nl-brand-navigation__responsive-search-third-part',
    searchInput: '#searchinput',
    searchpopupdiv: '#searchDiv',
    searchInputdiv: '#searchDivpage',
    apicallpath: 'data-apicallpath',
    noResultFound: 'data-errorText',
    quickResultLimit: 'data-quickResultLimit',
    customselect: '.custom-options .custom-option.selected',
    cmpActiveConst: '.cmp-tabs__tablist.login-tabs .cmp-tabs__tab.cmp-tabs__tab--active',
    searchPagination: '#searchDivpage .nl-search__Results_brands',
    landingFourGrid: '.bds-brand-layout__4-grid-card.card-col-lg .landing-content',
    tabComponentTabpanel: '.cmp-tabs__tabpanel',
    loginTitle: '.login-title',
    loginText: '.login-text',
    loginPath: '/en/login.html',
    breadcrumbClass: '.cmp-breadcrumb',
    tertiaryNavOffset: 30,
    timeInMillisecond: 2147483647,
    thousand: 1000,
    twenty: 20,
    hundred: 100,
    twoThousand: 100,
    headerContainer: '.nl-brand-navigation__container',
    notificationDiv: '#UpdateNotification',
    mdCutOff: 1278,
    disableIcon: 'disable-icon',
    secondaryIcon: '.secondary-navigation-icon',
    white: '#FFF',
    black: '#262626',
}
