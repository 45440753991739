/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-invalid-this */

import { commonVariable } from '../globalConstants/variableDeclaration.constant'

const bdsProfileComponent = (): void => {
    const $ = window.$
    setTimeout(() => {
        const bannerBrandList = sessionStorage.getItem('bannerBrands')
            ? $.parseJSON(sessionStorage.getItem('bannerBrands'))
            : []
        const brandConsumerList = sessionStorage.getItem('consumerBrand')
            ? $.parseJSON(sessionStorage.getItem('consumerBrand'))
            : []
        const userEmail = getCookie('user_email') ? getCookie('user_email') : ''
        let brandDropDownList
        $(document).ready(function () {
            if (bannerBrandList) {
                if (brandConsumerList && brandConsumerList.length > 0) {
                    const mergedArrayList = [...bannerBrandList, ...brandConsumerList]
                    const grades = new Map()
                    mergedArrayList.forEach(function (item) {
                        const brandItemId = item?.['brandId']
                        grades.set(JSON.stringify(brandItemId), item)
                    })
                    brandDropDownList = [...grades.values()]
                } else {
                    brandDropDownList = bannerBrandList
                }
            }
        })

        if (brandDropDownList) {
            getCheckBoxList(bannerBrandList, 'brandCheckbox')
            addBrandToDropdown(brandDropDownList, 'preferedBrandDropdown')
            dropDownLoader('preferedBrandDropdown')
        }

        if (brandConsumerList) {
            getCheckBoxList(brandConsumerList, 'consumerCheckbox')
        }

        /**
         * drowpdown functionality
         * @param {any} id dropdown fuction for particular parent div
         */
        function dropDownLoader(id: any) {
            document
                .querySelector('#' + id + ' .dropdownProfiles .select-wrapper')
                ?.addEventListener('click', function (this: any) {
                    this.querySelector('#' + id + ' .dropdownProfiles .select').classList.toggle('open')
                })
            for (const option of document.querySelectorAll('#' + id + ' .dropdownProfiles .custom-option') as any) {
                option.addEventListener('click', function (this: any) {
                    if (!this.classList.contains('selected')) {
                        this.parentNode
                            .querySelector('#' + id + ' .custom-option.selected')
                            ?.classList.remove('selected')
                        this.classList.add('selected')
                        this.closest('.select').querySelector('#' + id + ' ' + commonVariable.selectText).textContent =
                            this.textContent
                    }
                })
            }

            window.addEventListener('click', function (e) {
                const select = document.querySelector('#' + id + ' .dropdownProfiles .select')
                if (!select?.contains(e.target as Node)) {
                    select?.classList.remove('open')
                }
            })
        }

        /**
         * Add list in the dropdown
         * @param {any} brandList input text
         * @param {any} id list parent id
         */
        function addBrandToDropdown(brandList: any, id: any) {
            if (brandList.length === commonVariable.two || id === 'preferedBrandDropdown') {
                $.each(brandList, function (key: any, value: any) {
                    if (value.brandId !== 'bds' && value.brandId !== 'BDS') {
                        $('#' + id + ' .custom-options').append(
                            '<span class="custom-option" id=' + value.brandId + '>' + value.brandName + '</span>',
                        )
                    }
                })
            } else {
                $.each(brandList, function (key: any, value: any) {
                    $('#' + id + ' .custom-options').append(
                        '<span class="custom-option" id=' + value.brandId + '>' + value.brandName + '</span>',
                    )
                })
            }
        }
        /**
         * Selected brand dropdown
         * @param {any} defaultresult default selection
         * @param {any} brandDropDown dropdown list
         * @param {any} selectedId parent id
         */
        function defaultselection(defaultresult: any, brandDropDown: any, selectedId: any) {
            if (brandDropDown && brandDropDown.length === commonVariable.two) {
                $.each(brandDropDown, function (key: any, value: any) {
                    if (value.brandId !== 'bds' && value.brandId !== 'BDS') {
                        $('#' + selectedId + ' ' + commonVariable.customoption + value.brandId).addClass('selected')
                        if (document.querySelector('#' + selectedId + ' ' + commonVariable.selectText)) {
                            const element = document.querySelector(
                                '#' + selectedId + ' ' + commonVariable.selectText,
                            ) as HTMLElement
                            element.textContent = $(
                                '#' + selectedId + ' ' + commonVariable.customoption + value.brandId,
                            ).text()
                        }
                    }
                })
            } else {
                if (brandDropDown) {
                    $.each(brandDropDown, function (key: any, value: any) {
                        $('#' + selectedId + ' ' + commonVariable.customoption + value.brandId).removeClass('selected')
                    })
                }
                $('#' + selectedId + ' ' + commonVariable.customoption + defaultresult).addClass('selected')
                if (document.querySelector('#' + selectedId + ' ' + commonVariable.selectText)) {
                    const element = document.querySelector(
                        '#' + selectedId + ' ' + commonVariable.selectText,
                    ) as HTMLElement
                    element.textContent = $('#' + selectedId + ' ' + commonVariable.customoption + defaultresult).text()
                }
            }
        }

        if (getCookie(`preferBrand_${String(userEmail)}`) && brandDropDownList) {
            defaultselection(getCookie(`preferBrand_${String(userEmail)}`), brandDropDownList, 'preferedBrandDropdown')
        }

        JSON.parse(getCookie(`brandCheckbox_${String(userEmail)}`) || '[]').forEach(function (id: any) {
            $('#brandCheckbox input[name=' + id + ']').prop('checked', true)
        })
        JSON.parse(getCookie(`consumerCheckbox_${String(userEmail)}`) || '[]').forEach(function (id: any) {
            $('#consumerCheckbox input[name=' + id + ']').prop('checked', true)
        })
        $('.formProfile .savebutton').click(() => {
            const favs = $('.formProfile #brandCheckbox .checkboxmainContainer input:checked')
                .map(function (this: any) {
                    return this.name
                })
                .get()
            const consumerbox = $('.formProfile #consumerCheckbox .checkboxmainContainer input:checked')
                .map(function (this: any) {
                    return this.name
                })
                .get()
            setCookie(`brandCheckbox_${String(userEmail)}`, JSON.stringify(favs))
            setCookie(`consumerCheckbox_${String(userEmail)}`, JSON.stringify(consumerbox))
            if ($('#preferedBrandDropdown').find('.custom-options .custom-option.selected').attr('id') !== undefined) {
                setCookie(
                    `preferBrand_${String(userEmail)}`,
                    $('#preferedBrandDropdown').find('.custom-options .custom-option.selected').attr('id'),
                )
            }
            $('#subscriptionSave').show()
        })

        $('.popup-saveClose').click(function () {
            $('#subscriptionSave').hide()
        })

        /**
         * get checkbox list
         * @param {any} data dropdown data
         * @param {any} id parent id
         */
        function getCheckBoxList(data: any, id: any) {
            $.each(data, function (key: any, value: any) {
                if (value.brandId !== 'bds' && value.brandId !== 'BDS') {
                    const newHTML =
                        "<label class='checkboxmainContainer'>" +
                        value.brandName +
                        '<input name=' +
                        value.brandId +
                        " type='checkbox'>" +
                        "<span class='customcheck'></span>" +
                        '</label>'

                    $('#' + id + '').append(newHTML)
                }
            })
        }

        /**
         * cookies present
         * @param {any} cName cookies id
         * @return {any} cookies data
         */
        function getCookie(cName: any): any {
            const name = cName + '='
            const cDecoded = decodeURIComponent(document.cookie)
            const cArr = cDecoded.split('; ')
            let res = ''
            cArr.forEach(val => {
                if (val.indexOf(name) === 0) res = val.substring(name.length)
            })
            return res
        }

        /**
         * setcookies present
         * @param {any} cName cookies id
         * @param {any} value cookies value
         */
        function setCookie(cName: any, value: any) {
            const daysToExpire = new Date(commonVariable.timeInMillisecond * commonVariable.thousand).toUTCString()
            document.cookie = cName + '=' + escape(value) + '; expires=' + daysToExpire + '; path=/'
        }
    }, commonVariable.thousand)
}

export { bdsProfileComponent }
