import { bdsPageLoadComponent } from '../components/PageInitialLoad'
import { bdsUniversalsearchComponent } from '../components/SearchBdsComponent'
import { bdsTabComponent } from '../components/TabComponent'
import { bdsVideoComponent } from '../components/VideoComponent'
import { bdsFeatureListComponent } from '../components/FeatureListComponent'
import { bdsTertiaryNav } from '../components/TertiaryNavigation'
import { bdsProfileComponent } from '../components/ProfileComponent'
import { bdsNotificationComponent } from '../components/NotificationComponent'

export const staticFunctionMap: {
    [key: string]: {
        method: (element?: Element, params?: Record<string, string>) => void
        param?: Record<string, string>
    }
} = {
    page: { method: bdsPageLoadComponent },
    'cmp-experiencefragment--experience-fragment-header': { method: bdsUniversalsearchComponent },
    tabs: { method: bdsTabComponent },
    bdsvideocomponent: { method: bdsVideoComponent },
    'nl-sixCol-featuredlist': { method: bdsFeatureListComponent },
    tertiarynavigation: { method: bdsTertiaryNav },
    settingContainer: { method: bdsProfileComponent },
    settingList: { method: bdsNotificationComponent },
}
